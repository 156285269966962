import React, { createContext, useEffect, useState } from 'react';

const HeaderContext = createContext([{}, ()=> {}]);

const HeaderContextProvider = props => {
  const [options, setOptions] = useState(props);

  useEffect(() => setOptions(props), [props]);

  return (
    <HeaderContext.Provider value={options}>
      {props.children}
    </HeaderContext.Provider>
  );
};

export {
  HeaderContext,
  HeaderContextProvider
};
