import './AppMenu.scss';
import React, { useContext } from 'react';
import { Button } from 'react-md';
import * as permissions from '../app-permissions';
import {
  AnalyticsIcon,
  ApiIcon,
  ApiProvisioningIcon,
  DevicesIcon,
  EducationIcon,
  LegacyPortalIcon,
  LiveIcon,
  NetworkDiagramIcon,
  PrepaidPassManagerIcon,
  SchedulerIcon,
  SignalIcon,
  UsersIcon,
  VipsIcon
} from './icons';
import { HeaderContext } from '../Header/Header.context';

const AppMenu = () => {
  const { authUser } = useContext(HeaderContext);

  function checkRole(allowedRoles) {
    const user = authUser;
    const roles = user.g.map(org => org.r);

    let check = false;

    roles.forEach(role => {
      if (allowedRoles.includes(role)) {
        check = true;
      }
    });

    return check;
  };

  return (
    <div className="app-container">
      {process.env.REACT_APP_PORTAL_URL && checkRole(permissions.LEGACY_PORTAL) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_PORTAL_URL}>
          <LegacyPortalIcon />
          <div className="app-name">Legacy Portal</div>
        </Button>
      )}
      {process.env.REACT_APP_LIVE_URL && checkRole(permissions.LIVE) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_LIVE_URL}>
          <LiveIcon />
          <div className="app-name">Live</div>
        </Button>
      )}
      {process.env.REACT_APP_ANALYTICS_URL && checkRole(permissions.ANALYTICS) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_ANALYTICS_URL}>
          <AnalyticsIcon />
          <div className="app-name">Analytics</div>
        </Button>
      )}
      {process.env.REACT_APP_SCHEDULER_URL && checkRole(permissions.SCHEDULER) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_SCHEDULER_URL}>
          <SchedulerIcon />
          <div className="app-name">Scheduler</div>
        </Button>
      )}
      {process.env.REACT_APP_API_URL && checkRole(permissions.API) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_API_URL}>
          <ApiIcon />
          <div className="app-name">API</div>
        </Button>
      )}
      {process.env.REACT_APP_DEVICES_URL && checkRole(permissions.DEVICES) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_DEVICES_URL}>
          <DevicesIcon />
          <div className="app-name">Devices</div>
        </Button>
      )}
      {process.env.REACT_APP_EDUCATION_URL && checkRole(permissions.EDUCATION) &&
        <Button flat className="app-icon" target="_blank" href={process.env.REACT_APP_EDUCATION_URL}>
          <EducationIcon />
          <div className="app-name">Education</div>
        </Button>
      }
      {process.env.REACT_APP_VIP_MANAGER_URL && checkRole(permissions.VIP_MANAGER) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_VIP_MANAGER_URL}>
          <VipsIcon />
          <div className="app-name">Vip Manager</div>
        </Button>
      )}
      {process.env.REACT_APP_PREPAID_PASS_MANAGER_URL && checkRole(permissions.PREPAID_PASS_MANAGER) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_PREPAID_PASS_MANAGER_URL}>
          <PrepaidPassManagerIcon />
          <div className="app-name">Prepaid Pass Manager</div>
        </Button>
      )}
      {process.env.REACT_APP_API_PROVISIONING_URL && checkRole(permissions.API_PROVISIONING) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_API_PROVISIONING_URL}>
          <ApiProvisioningIcon />
          <div className="app-name">API Provisioning</div>
        </Button>
      )}
      {process.env.REACT_APP_USERS_URL && checkRole(permissions.USERS) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_USERS_URL}>
          <UsersIcon />
          <div className="app-name">Users</div>
        </Button>
      )}
      {process.env.REACT_APP_NETWORK_DIAGRAM_URL && checkRole(permissions.NETWORK_DIAGRAM) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_NETWORK_DIAGRAM_URL}>
          <NetworkDiagramIcon />
          <div className="app-name">Network Diagram</div>
        </Button>
      )}
      {process.env.REACT_APP_SIGNAL_URL && checkRole(permissions.SIGNAL) && (
        <Button flat className="app-icon" href={process.env.REACT_APP_SIGNAL_URL}>
          <SignalIcon />
          <div className="app-name">Signal</div>
        </Button>
      )}
      <Button flat className="app-container-view-all" href={process.env.REACT_APP_SUITE_URL}>view all apps</Button>
    </div>
  );
};

export default AppMenu;
