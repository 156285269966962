import './Header.scss';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import stickyBits from 'stickybits';
import DesktopHeader from './DesktopHeader';
import GhostingView from '../GhostingView/GhostingView';
import TagManager from 'react-gtm-module';
import { HeaderContextProvider } from './Header.context';

const Header = ({
  children,
  className,
  signOut,
  authUser,
  backgroundColor,
  buttonColor,
  logoSrc,
  logoWidth,
  ghostingNode,
  adminMenu,
  appMenuActive,
  style,
  logoClick
}) => {
  useEffect(() => {
    stickyBits('#header-container', {
      useStickyClasses: true,
      parentClass: 'sticky-parent',
      stickyClass: 'sticky',
      stuckClass: 'stuck',
    });

    function createAuthUserData() {
      return {
        id: (authUser.gk ? authUser.gk : authUser.pk) || '',
        email: authUser.e || '',
        partyKey: authUser.pk || '',
        firstName: authUser.fn || '',
        lastName: authUser.ln || '',
        displayName: authUser.dn || '',
        role: authUser.g.map(o => o.r).join(','),
        legacyKey: authUser.lk || '',
        legacyOperatorKey: authUser.lok || '',
        environment: authUser.ne,
        ghostingKey: authUser.gk || ''
      };
    }

    if (process.env.REACT_APP_GTM_ID) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID
      });

      if (!!authUser && window.pendo) {
        window.pendo.initialize({
          visitor: createAuthUserData(),
          account: {
            id: authUser.g.map(o => o.o).join(',')
          }
        });

        if (authUser.gk) {
          window.pendo.track('GHOSTING', {
            ghostingKey: authUser.gk,
            clientKey: authUser.pk
          });
        }
      }
    }

    return () => {
      document.getElementById('root').classList.remove('sticky-parent');
    };
  }, [authUser]);

  function checkRole(user, allowedRoles) {
    const roles = user.g.map(org => org.r);
    let check = false;

    roles.forEach(role => {
      if (allowedRoles.includes(role)) {
        check = true;
      }
    });

    return check;
  }

  return (
    <HeaderContextProvider
      authUser={authUser}
      signOut={signOut}
      adminMenu={adminMenu}
      buttonColor={buttonColor}
      appMenuActive={appMenuActive}
    >
      <div
        id="header-container"
        className={`${className ? className : ''}`}
        style={style}
      >
        <DesktopHeader
          backgroundColor={backgroundColor}
          logoSrc={logoSrc}
          logoWidth={logoWidth}
          logoClick={logoClick}
        />
        {children &&
          <div className="header-child-container" style={{ backgroundColor: backgroundColor }}>
            {children}
          </div>
        }
        {authUser.gk && <GhostingView authUser={authUser} />}
      </div>
      {ghostingNode && (checkRole(authUser, ['GLOBAL_ADMIN']) || authUser.gk) && ghostingNode}
    </HeaderContextProvider>
  );
};

Header.propTypes = {
  adminMenu: PropTypes.node,
  appMenuActive: PropTypes.bool,
  authUser: PropTypes.object,
  backgroundColor: PropTypes.string,
  buttonColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  ghostingNode: PropTypes.node,
  logoClick: PropTypes.func,
  logoSrc: PropTypes.string.isRequired,
  logoWidth: PropTypes.number,
  signOut: PropTypes.func.isRequired,
  style: PropTypes.object,
};

Header.defaultProps = {
  appMenuActive: true,
  // backgroundColor: '#FFF',
  // buttonColor: '#A8A8A8',
  className: '',
  children: null,
  logoWidth: 100,
  style: null
};

export default Header;
