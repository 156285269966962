/* eslint-disable */
import React from 'react';

const SignalIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <circle cx="19" cy="19" r="11" fill="#1FC09A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.2635 11.0008C17.6437 11.0183 17.9561 11.325 18.0014 11.7255L18.9995 20.5387L19.7397 17.2906C19.804 17.0086 20.0035 16.7832 20.265 16.6973C20.5264 16.6114 20.8113 16.6776 21.0148 16.8716L22.1387 17.9433H27.2229C27.6521 17.9433 28 18.3121 28 18.767C28 19.222 27.6521 19.5908 27.2229 19.5908H21.8406C21.6487 19.5908 21.4636 19.5155 21.3209 19.3794L20.9444 19.0204L19.4974 25.3697C19.4091 25.7571 19.0717 26.022 18.6963 25.9986C18.321 25.9751 18.0153 25.6702 17.9704 25.2744L17.0222 16.9014L16.621 18.9356C16.5457 19.3173 16.2282 19.5908 15.8603 19.5908H10.7771C10.3479 19.5908 10 19.222 10 18.767C10 18.3121 10.3479 17.9433 10.7771 17.9433H15.2289L16.4692 11.6551C16.5471 11.2603 16.8833 10.9833 17.2635 11.0008Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.57404 18.845C8.57404 13.1725 13.1725 8.57404 18.8451 8.57404C24.5176 8.57404 29.1161 13.1725 29.1161 18.845C29.1161 24.5176 24.5176 29.1161 18.8451 29.1161C13.1725 29.1161 8.57404 24.5176 8.57404 18.845ZM18.8451 7C12.3032 7 7 12.3032 7 18.845C7 25.3869 12.3032 30.6901 18.8451 30.6901C21.6197 30.6901 24.1715 29.7361 26.1904 28.1382L34.0522 36L36 34.0522L28.1382 26.1904C29.7361 24.1715 30.6901 21.6197 30.6901 18.845C30.6901 12.3032 25.3869 7 18.8451 7Z" fill="black"/>
  </svg>
);

export default SignalIcon;
/* eslint-enable */
