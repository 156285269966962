import './UserMenu.scss';
import React, { useContext } from 'react';
import SignOutButton from '../SignOutButton/SignOutButton';
import { HeaderContext } from '../Header/Header.context';

const UserMenu = () => {
  const { authUser, signOut, adminMenu } = useContext(HeaderContext);

  function isGlobalAdmin() {
    if (
      authUser.g.filter(rel => rel.r === 'GLOBAL_ADMIN').length > 0 ||
      authUser.gk
    ) {
      return true;
    };
  };

  return (
    <div>
      <div className="user-menu-container">
        <div className="user-menu-top">
          <div className="user-avatar">
            {!!authUser && `${authUser.fn[0]}${authUser.ln[0]}`}
          </div>
          <div className="user-details">
            <div className="user-name">
              {!!authUser && `${authUser.fn} ${authUser.ln}`}
            </div>
            <div className="user-email">
              {!!authUser && `${authUser.e}`}
            </div>
          </div>
        </div>
        <div className="user-menu-bottom">
          <SignOutButton id="user-dropdown-sign-out" signOut={signOut} />
        </div>
      </div>
      {isGlobalAdmin() && adminMenu}
    </div>
  );
};

export default UserMenu;
